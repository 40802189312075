<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Confirmar Embarque
    </v-subheader>
    <v-form @submit.prevent>
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Confirmar Embarque</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12">
              <span class="primary--text">Código de Barras</span>
              <v-text-field
                v-model="code"
                placeholder="Cole ou digite o código de barras para confirmar o embarque"
                required
                append-icon="mdi-barcode"
                autofocus
                @keyup="code ? searchData() : null"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-15">
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <b>Data/Hora da Compra:</b>
              {{ data.datahora }}
            </v-list-item-title>
            <v-list-item-title>
              <b>Data/Hora do Embarque:</b>
              {{ data.datahora_embarque }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title>
              <b>Passageiro:</b>
              {{ data.nome }}
            </v-list-item-title>
            <v-list-item-title>
              <b>Documento:</b>
              {{ data.documento }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title class="text-capitalize">
              <b>Tipo de Venda:</b>
              {{ data.descricao ? data.descricao.toLowerCase() : null }}
            </v-list-item-title>
            <v-list-item-title>
              <b>Embarcado:</b>
              {{
                data.embarcado == "N"
                  ? "Não"
                  : data.embarcado == "S"
                  ? "Sim"
                  : ""
              }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form py-0" absolute>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="data.embarcado == 'N'"
          text
          depressed
          dark
          @click="confirmEmbarque()"
          class="my-2"
        >
          Confirmar Embarque
        </v-btn>
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      retorno: "",
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      code: "",
      agencia: [],
      data: [],
    };
  },
  created() {
    this.loadAgency();
  },
  methods: {
    searchData() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/embarque/${this.code}`)
        .then((r) => {
          this.data = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    confirmEmbarque() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/embarque/confirmar/${this.code}`, { code: this.code })
        .then((r) => {
          this.data = r.data;
          this.searchData();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadAgency() {
      this.$http
        .get(`/pdv/agencias/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.agencia = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
  },
  components: { BackArrow },
};
</script>

<style src="./style.vue"></style>
